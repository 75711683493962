import { Link } from 'gatsby'
import { Seo, ContactForm } from '~/components'
import Arrow from '~/images/common_icon_arrow_r.svg'

const ContactRepair = () => (
  <>
    <Seo
      title="ご注文時の修理等に関するお問い合わせ"
      pageUrl="contact-repair"
    />
    <div className="pageComponent">
      <article
        className="contact"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="titleComponent contentComponent">
          <h1 className="heading pageTitle">CONTACT</h1>
          <p>ご注文時の修理等に関するお問い合わせ</p>
        </header>
        <nav className="contentComponent">
          <ul className="breadcrumbComponent mincho">
            <li>
              <Link to="/" itemProp="url">
                TOP
              </Link>{' '}
              <span>
                <Arrow />
              </span>
            </li>
            <li>CONTACT</li>
          </ul>
        </nav>
        <div className="contentSubComponent">
          <ContactForm formId="e7acb964-051a-40f8-99b5-aec39166649c" />
        </div>
      </article>
    </div>
  </>
)

export default ContactRepair
